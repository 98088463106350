<template>
    <div id="m_goodsall">
        <div class="top d_flex">
            <div class="logo" @click="$router.push('/m_index')"><img :src="$store.state.tenantInfo.logo | fullPath" alt=""></div>
            <div class="right_content d_flex">
                <template v-if="!isLogin">
                    <span class="login" @click="$router.push('/m_login')">登录</span>
                    <span class="registered" @click="$router.push('/m_register')">注册</span>
                </template>
                <div class="user" v-else><img src="@/assets/img/mobile/10.png" alt=""></div>
                <span @click="show=!show"><img src="@/assets/img/mobile/nav_bar.png" alt=""></span>
            </div>
        </div>
        <div class="d_flex filter_content">
            <div class="f_w d_flex">
                <div>当前筛选：</div>
                <div class="filter_result">
                    <span>{{typeName}}</span>
                    <span>{{storeName}}</span>
                </div>
            </div>
            <div class="filter_icon d_flex" @click="changeFilterDialog">
                <img src="@/assets/img/mobile/08.png" alt=""><span>筛选</span>
            </div>
        </div>
        <div class="type3 d_flex">
            <div @click="formData.sort = 1,search()" :class="formData.sort==1 ? 'active':''">价格最低</div>
            <div @click="formData.sort = 3,search()" :class="formData.sort==3 ? 'active':''">销量最高</div>
            <div @click="formData.sort = 4,search()" :class="formData.sort==4 ? 'active':''">重量最大</div>
            <div @click="formData.sort = 5,search()" :class="formData.sort==5 ? 'active':''">最新发布</div>
            <transition name="fade3">
                <div class="dialog" v-if="show2">
                    <p class="t1">筛选分类：</p>
                    <div class="c1">
                        <span @click="formData.type = '',search(1,'全部分类')" :class="formData.type == '' ? 'active':''" >全部分类</span>
                        <span @click="formData.type = item.id,search(1,item.name)" :class="formData.type == item.id ? 'active':''" v-for="item in typeList">{{item.name}}</span>
                    </div>
                    <p class="t1">仓储：</p>
                    <div class="c1">
                        <span @click="formData.storeId = '',search(2,'全部仓储')" :class="formData.storeId=='' ? 'active':''">全部仓储</span>
                        <span @click="formData.storeId = item.id,search(2,item.name)" :class="formData.storeId == item.id ? 'active':''"  v-for="item in storeList">{{item.name}}</span>
                    </div>
                </div>
            </transition>
            <transition name="fade2">
                <div class="mask2" v-if="show2" @click="changeFilterDialog"></div>
            </transition>
        </div>
        <div class="good_list">
            <div class="item" v-for="item in goodsList" @click="buyGoods(item)">
                <div class="img_wrap"><img :src="item.cover | fullPath" alt=""></div>
                <div class="title">{{item.name}}</div>
                <div class="other clearfix">
                    <span class="price fl">￥{{(item.cost+item.profit).toFixed(2)}}</span>
                    <span class="wei fr">{{item.weight}} kg/件</span>
                </div>
                <div class="btn">立即购买</div>
            </div>
        </div>
        <div id="loading" ref="load">正在加载中...</div>
        <div class="bottom_content d_flex">
            <div class="b_item">
                <div class="d_flex">
                    <img src="@/assets/img/bottom1.png" alt="">
                    <p>
                        <span>降低成本</span><br>
                        <span>帮你降低50%发货成本</span>
                    </p>
                </div>
                <div class="d_flex">
                    <img src="@/assets/img/bottom2.png" alt="">
                    <p>
                        <span>保证品质</span><br>
                        <span>注重品质，物廉价美</span>
                    </p>
                </div>
            </div>
            <div class="b_item">
                <div class="d_flex">
                    <img src="@/assets/img/bottom4.png" alt="">
                    <p>
                        <span>去除库存</span><br>
                        <span>回收库存、去礼品库</span>
                    </p>
                </div>
                <div class="d_flex">
                    <img src="@/assets/img/bottom3.png" alt="">
                    <p>
                        <span>多种选择</span><br>
                        <span>低价礼品、配重礼品、高端礼品</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="friend_link">
            <span>友情链接：</span>
            <a :href="item.url" v-for="item in linksList" target="_blank">{{item.name}}</a>
        </div>
        <div class="copyright" v-if="$store.state.tenantInfo.beianhao">
            <img src="@/assets/img/guohui.png" alt="">
            {{$store.state.tenantInfo.beianhao}}
        </div>
        <transition name="fade">
            <div class="mask" v-if="show" @click="show=false">
            </div>
        </transition>
        <transition name="fade1">
            <div class="mask_left" v-if="show">
                <div @click="$router.push('/m_index')">首页</div>
                <div @click="$router.push('/m_goodsall')">礼品商城</div>
                <div @click="$router.push('/member/index')">会员中心</div>
                <div @click="$router.push('/member/vip')">收费标准</div>
                <div @click="$router.push('/member/guide')">代发指南</div>
                <!-- <div v-if="$store.state.showOtherSite" @click="$router.push('/member/other_site')">源码搭建</div> -->
                <div @click="openQQ">在线客服</div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            isLogin:false,
            show:false,
            show2:false,
            loading:false,
            finish:false,
            formData:{
                pageNo:1,
                pageSize:20,
                sort:'',
                storeId:'',
                type:'',
            },
            typeName:'全部分类',
            storeName:'全部仓储',
            goodsList:[],
            typeList:[],
            storeList:[],
        }
    },
    created(){
        let token = localStorage.getItem('dftToken')
        if(token){
            this.isLogin = true
        }else{
            this.isLogin = false
        }
        this.getTypeList();
        this.getStoreList();
        this.getGoodsList();

    },
    mounted(){
        let timer
        window.onscroll= ()=>{
            //变量t是滚动条滚动时，距离顶部的距离
            clearTimeout(timer)
            timer = setTimeout(()=>{
                let t = document.documentElement.scrollTop||document.body.scrollTop;
                let el = this.$refs.load
                let top = el.getBoundingClientRect().top
                if(top < 957){
                    if(this.loading || this.finish){
                        return
                    }
                    this.formData.pageNo ++ 
                    this.getGoodsList()
                }
            },300)
        }
    },
    watch: {},
    methods: {
        search(num,name){
            this.goodsList = []
            this.finish = false
            this.formData.pageNo = 1;
            this.getGoodsList();
            if(num==1){
                this.typeName = name
            }else if(num==2){
                this.storeName = name
            }
        },
        changeFilterDialog(){
            this.show2 = !this.show2
        },
        //查看全部分类
        getTypeList(){
            this.$request.post({
                url:'/bus/goodsInfo/public/goodsTypes',
                params:{},
                success: res => {
                    this.typeList = res;
                }
            })
        },
        //查看全部仓库
        getStoreList(){
            this.$request.post({
                url:'/bus/goodsInfo/public/getStore',
                params:{},
                success: res => {
                    this.storeList = res;
                }
            })
        },
        //查看商品列表
        getGoodsList(){
            this.loading=true
            this.$request.post({
                url:'/bus/goodsInfo/public/list',
                params:this.formData,
                success: res => {
                    this.goodsList.push(...res.list)
                    if(this.goodsList.length == res.total){
                        this.finish = true
                        this.$refs.load.innerText = '没有更多了'
                    }
                },
                finally:res => {
                    this.loading = false
                }
            })
        },
        //立即购买
        buyGoods(item){
            this.$store.commit('getGoodsInfo',item)
            this.$router.push('/member/send_order');
        },
        openQQ() {
            var url = 'tencent://message/?uin=' + this.$store.state.tenantInfo.qq + '&amp;Site=&amp;Menu=yes';
            window.open(url,'_self')
        },
    }
}
</script>

<style lang="scss" scoped>
#m_goodsall{
    min-height: 100%;
    padding-top: 101px;
    .top{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        box-sizing: border-box;
        padding: 0 14px;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        height: 44px;
        z-index: 2;
        .logo{
            align-items: center;
            width: 57.6px;
            img{
                width: 100%;
                display: block;
            }
        }
        .right_content{
            align-items: center;
            span{
                &:last-child{
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    img{
                        width: 100%;
                    }
                }
            }
            .login{
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                background: #FF8800;
                border-radius: 12px;
                padding: 2px 10px;
            }
            .registered{
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FF8800;
                padding: 2px 10px;
                border: 1px solid #ff8800;
                border-radius: 12px;
                margin: 0 14px 0 5px;
            }
            .user {
                width: 18px;
                height: 18px;
                display: block;
                margin-right: 18px;
                img{
                    width: 100%;
                    height: 100%;
                    display: block;
                }
            }
        }
    }
    .filter_content{
        position: fixed;
        top: 44px;
        width: 100%;
        box-sizing: border-box;
        background: #fff;
        align-items: center;
        justify-content: space-between;
        padding: 0 14px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        z-index: 2;
        .f_w{
            align-items: center;
        }
        .filter_result{
            span{
                font-size: 10px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FF8800;
                padding: 2px 7px;
                border: 1px solid #FF8800;
                border-radius: 3px;
                background: #FFF3E6;
                display: inline-block;
                &:not(:first-child){
                    margin-left: 5px;
                }
            }
        }
        .filter_icon{
            font-size: 0;
            border-left: 1px solid #CCCCCC;
            align-self: stretch;
            align-items: center;
            padding-left: 12px;
            span{
                font-size: 12px;
                font-family: Microsoft YaHei;
                color: #999999;
            }
            img{
                width: 16px;
                height: 16px;
                display: inline-block;
                margin-right: 5px;
            }
        }
    }
    .type3{
        position: fixed;
        top: 66px;
        width: 100%;
        box-sizing: border-box;
        background: #fff;
        height: 35px;
        line-height: 35px;
        padding: 0 15px;
        justify-content: space-around;
        box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
        div{
            font-size: 12px;
            font-family: Microsoft YaHei;
            color: #666666;
        }
        .active{
            color: #FF8800;
        }
        .dialog{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            padding: 0 14px 14px;
            box-sizing: border-box;
            background: #fff;
            line-height: normal;
            z-index: 2;
            transition: transform 0.5s;
            .t1{
                margin-top: 9px;
            }
            .c1{
                font-size: 0;
                span{
                    display: inline-block;
                    font-size: 11px;
                    color: #999999;
                    background: #eee;
                    border-radius: 3px;
                    padding: 4px 8px;
                    margin-top: 5px;
                    margin-right: 7px;
                }
                .active{
                    color: #FF8800;
                    background: #FFF3E6;
                    border: 1px solid #FF8800;
                    box-sizing: border-box;
                }
            }
        }
        .dialog_t{
            transform: translate(0);
        }
        .mask2{
            position: fixed;
            top: 44px;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0,0,0,0.5);
        }
    }
    .good_list{
        font-size: 0;
        padding:0 14px 0;
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        .item{
            width: 49%;
            display: inline-block;
            border: 1px solid #eee;
            box-sizing: border-box;
            vertical-align: top;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            &:nth-child(even){
                margin-left: 2%;
            }
            &:nth-child(n + 3){
                margin-top: 8px;
            }
            .img_wrap{
                height: calc(49vw - 14px);
            }
            .title{
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: 10px;
                color: #999;
                line-height: 22px;
                padding: 0 5px;
            }
            .other{
                padding: 0 5px;
                .price{
                    font-size: 14px;
                    color: #f80;
                }
                .wei{
                    font-size: 11px;
                    color: #666;
                }
            }
            .btn{
                line-height: 28px;
                text-align: center;
                color: #f80;
                border: 1px solid #f80;
                box-sizing: border-box;
                border-radius: 4px;
                margin: 6px 9px 2% 9px;
                display: block;
                height: 28px;
                font-size: 12px;
                &:hover{
                    color: #fff;
                    background: #f80;
                }
            }
        }
    }
    #loading{
        display: block;
        text-align: center;
        color: #aaa;
        margin-top: 10px;
    }
    .bottom_content{
        padding: 0 14px;
        margin-top: 10px;
        .b_item{
            flex: 1;
            div{
                img{
                    width: 74px;
                }
                p{
                    margin-top: 15px;
                    span{
                        &:first-child{
                            color: #000000;
                            font-size: 14px;
                            font-weight: bold;
                        }
                        &:last-child{
                            font-size: 10px;
                            color: #999;
                        }
                    }
                }
            }
        }
    }
    .friend_link{
        margin-top: 15px;
        text-align: center;
        span{
            font-size: 10px;
            color: #333333;
            &:first-child{
                color: #666666;
            }
        }
        a{
            margin-right: 5px;
            font-size: 10px;
        }
    }
    .copyright{
        text-align: center;
        font-size: 8px;
        color: #333333;
        margin-top: 9px;
        padding-bottom: 42px;
        img{
            height: 16px;
        }
    }
    .mask{
        position: fixed;
        top: 44px;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0,0,0,0.5);
        z-index: 11;
    }
    .mask_left{
        width: 35%;
        text-align: center;
        position: fixed;
        top: 44px;
        background: #fff;
        bottom: 0;
        right: 0;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        z-index: 11;
        div{
            padding: 9px 0;
            border-bottom: 1px solid #eee;
        }
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade1-enter, .fade1-leave-to {
        transform: translateX(100%);
    }
    .fade1-enter-active, .fade1-leave-active {
        transition: all .5s;
    }
    .fade2-enter, .fade2-leave-to {
        opacity: 0;
    }
    .fade2-enter-active, .fade2-leave-active {
        transition: opacity .5s;
    }
    .fade3-enter, .fade3-leave-to {
        transform: translateY(-100%);
    }
    .fade3-enter-active, .fade3-leave-active {
        transition: all .5s;
    }
}
</style>